body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.map-container {
  width: 80%;
  height: 600px;
  margin: 0 auto;
  border: 1px solid #eee;
}
.marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.top-flex {
  display: flex;
  margin: 20px 0 0;
  justify-content: center;
  gap: 40px;
}

.legend {
  background: white;
  padding: 10px 20px;
}
.legend > div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 12px;
}
.legend .dot {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
}

.tag {
  display: inline-block;
  padding: 4px 8px;
  background: #eee;
  margin-right: 8px;
}
